
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core//mixins/mixin";
import { NavBar, Row, Image } from "vant";
import ItemTitle from "./ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class OrderDetails extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: true,
  })
  public isHead!: boolean; //有没有标题
  @Prop({
    type: Boolean,
    default: false,
  })
  public isOrder!: boolean; //是订单详情里使用
  @Prop({
    type: Boolean,
    default: true,
  })
  public isMall!: boolean; //是否是商品订单
  @Prop({
    type: Boolean,
    default: true,
  })
  public isBorder!: boolean;
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public info!: Array<order.PayInfo>; //订单列表
  @Prop({
    type: Number,
    default: null,
  })
  public allPrice!: number; //票务总价

  @Prop({
    type: String,
    default: "",
  })
  public orderStatus!: string; //票务总价

  @Prop({
    type: Number,
    default: null,
  })
  public orderFreightAmt!: number; //运费
  @Prop({
    type: Boolean,
    default: null,
  })
  public showPostCost!: boolean; //是否展示邮费
  @Prop({
    type: Boolean,
    default: null,
  })
  public postFree!: boolean; //是否 显示 免邮费

  @Prop({
    type: Number,
    default: null,
  })
  public policyDiscAmt!: number; //优惠折扣

  @Prop({
    type: Number,
    default: null,
  })
  public couponDiscAmt!: number; //优惠券

  @Prop({
    type: Number,
    default: null,
  })
  public giftCardAmt!: number; //礼包卡

  @Prop({
    type: Number,
    default: null,
  })
  public integralSubsidy!: number; //文慧卷

  otherList: Array<order.PayInfo> = []; //商品总价
  @Watch("info", { immediate: true, deep: true })
  updateInfo(): void {
    if (this.info) {
      let arr = this.info.filter((item) => {
        return item.key !== "现金";
      });
      this.otherList = arr ? arr : this.otherList;
    }
  }
  /*
   *待付款及交易关闭状态文案 为：“应付”；待收货、待发货、交易完成状态文案为：“实付”。
   */
  getName(status: string): string {
    let name = "应付金额";
    if (status !== "PEND_PAYMENT" && status !== "CANCLE") {
      name = "实付金额";
    }
    return name;
  }
}

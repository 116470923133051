
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core//mixins/mixin";
import { NavBar, Row, Image } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class OrderDetails extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: true,
  })
  public isHead!: boolean; //是否有头部标题
  @Prop({
    type: Boolean,
    default: true,
  })
  public isBorder!: boolean; //有没有下划线
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public info!: Array<order.SeatInfo>; //商品信息
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"seen"},[_c('van-popup',{style:({ height: '75%' }),attrs:{"position":"bottom","close-on-click-overlay":false,"round":""},model:{value:(_vm.dataInfo.show),callback:function ($$v) {_vm.$set(_vm.dataInfo, "show", $$v)},expression:"dataInfo.show"}},[_c('van-row',{staticClass:"title"},[_c('img',{staticClass:"t-pic",attrs:{"src":require('@/assets/images/Mall/s1.png')},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}}),_vm._v(" 立即兑换 ")]),_c('van-row',{staticClass:"s-main"},[_c('van-row',{attrs:{"type":"flex","align":"center"}},[(_vm.defaultPic.imgUrl)?_c('van-image',{staticStyle:{"margin-right":"0.2rem"},attrs:{"fit":_vm.imageFit,"width":"1.3rem","height":"1.3rem","src":_vm.defaultPic.imgUrl},on:{"click":function($event){$event.preventDefault();return _vm.divPic.apply(null, arguments)}}}):[(_vm.defaultIndexPic)?_c('van-image',{staticStyle:{"margin-right":"0.2rem"},attrs:{"fit":_vm.imageFit,"width":"1.3rem","height":"1.3rem","src":_vm.defaultIndexPic},on:{"click":function($event){$event.preventDefault();return _vm.divPic.apply(null, arguments)}}}):_c('van-image',{staticStyle:{"margin-right":"0.2rem"},attrs:{"fit":_vm.imageFit,"width":"1.3rem","height":"1.3rem","src":require('@/assets/images/placeholder/banner.png')}})],(_vm.defaultPic.specsMemberPrices)?_c('div',{staticClass:"price"},[(
              _vm.defaultPic.specsMemberPrices[0].amount === 0 &&
              _vm.defaultPic.specsMemberPrices[0].integral === 0 &&
              _vm.defaultPic.specsMemberPrices[0].printing === 0
            )?[_c('span',[_vm._v(_vm._s(_vm.defaultPic.specsMemberPrices[0].amount))]),_c('span',{staticClass:"unit"},[_vm._v("元")])]:_vm._e(),(
              _vm.defaultPic.specsMemberPrices[0].amount &&
              _vm.defaultPic.specsMemberPrices[0].amount > 0
            )?[_c('span',[_vm._v(_vm._s(_vm.defaultPic.specsMemberPrices[0].amount.toFixed(2).split(".")[0]))]),_c('span',{staticClass:"unit"},[_vm._v("."+_vm._s(_vm.defaultPic.specsMemberPrices[0].amount .toFixed(2) .split(".")[1])+"元")])]:_vm._e(),(
              Number(_vm.defaultPic.specsMemberPrices[0].amount) > 0 &&
              Number(_vm.defaultPic.specsMemberPrices[0].integral) > 0
            )?_c('span',[_vm._v("+")]):_vm._e(),(Number(_vm.defaultPic.specsMemberPrices[0].integral) > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.defaultPic.specsMemberPrices[0].integral)+" "),_c('span',{staticClass:"unit"},[_vm._v("积分")])]):_vm._e(),(
              Number(_vm.defaultPic.specsMemberPrices[0].integral) > 0 &&
              Number(_vm.defaultPic.specsMemberPrices[0].printing) > 0
            )?_c('span',[_vm._v("+")]):_vm._e(),(Number(_vm.defaultPic.specsMemberPrices[0].printing) > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.defaultPic.specsMemberPrices[0].printing)+" "),_c('span',{staticClass:"unit"},[_vm._v("印花")])]):_vm._e()],2):_vm._e()],2),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticStyle:{"margin-top":"0.4rem"}},[_c('div',{staticClass:"sp-s-name"},[_vm._v(" "+_vm._s(item.specsTypeName)+"（"+_vm._s(item.commoditySpecsInfoDtos ? item.commoditySpecsInfoDtos.length : 0)+"） ")]),_c('div',{staticClass:"sp-s-opt"},_vm._l((item.commoditySpecsInfoDtos),function(item2,index2){return _c('div',{key:index2,staticClass:"sp-s-o-detai",on:{"click":function($event){$event.preventDefault();return _vm.chspecsz(
                item2.specsId || '',
                index,
                item2.isShow || false,
                index2
              )}}},[_c('div',{staticClass:"sp-s-o-d-name",class:[
                _vm.subIndex[index] == index2 ? 'sp-s-o-d-name-active' : '',
              ]},[_vm._v(" "+_vm._s(item2.specsName)+" ")]),(!item2.isShow)?_c('img',{staticClass:"sp-s-o-d-out",attrs:{"src":require('@/assets/images/Mall/s2.png')}}):_vm._e()])}),0),_c('div',{staticClass:"sp-line"})])}),_c('remain-stock',{attrs:{"stock-info":_vm.stockInfo,"pur":_vm.dataInfo.order.num}})],2),_c('div',{staticClass:"footer",class:{ stockout: _vm.stockout }},[_c('div',{staticClass:"btn",on:{"click":function($event){$event.preventDefault();return _vm.btn.apply(null, arguments)}}},[_vm._v("立即兑换")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-code"},[_c('div',{staticClass:"c-content",class:{ main: _vm.isBorder }},[[_c('van-row',{staticClass:"c-title",attrs:{"type":"flex","justify":"center","align":"center"}},[_vm._v("取票码："+_vm._s(_vm.ticketCode))]),_c('van-row',{staticClass:"c-m-code",attrs:{"type":"flex","justify":"center","align":"center"}},[_c('div',{ref:"qrCodeUrl",staticClass:"qrcode",style:({
            opacity:
              _vm.orderStatus === 'ORDER_COMPLATE' && _vm.productSourceEnum === 'POLY'
                ? 0.2
                : 1,
          })}),(
            _vm.orderStatus === 'ORDER_COMPLATE' && _vm.productSourceEnum === 'POLY'
          )?_c('img',{staticClass:"c-pic",attrs:{"src":require('@/assets/images/PerOrder/c1.png'),"alt":""}}):_vm._e()])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
import QRCode from "qrcodejs2";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class PerCode extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: true,
  })
  public isBorder!: boolean;
  @Prop({
    type: String,
    default: "",
  })
  public ticketCode!: string;
  @Prop({
    type: String,
    default: "",
  })
  public orderStatus!: string;

  @Prop({
    type: String,
    default: "",
  })
  public productSourceEnum!: string;

  mounted(): void {
    this.creatQrCode();
    console.log("orderStatus", this.orderStatus);
  }

  /*
   *数字转二维码
   */
  creatQrCode(): void {
    new QRCode(this.$refs.qrCodeUrl, {
      text: `${this.ticketCode}`, // 需要转换为二维码的内容
      // width: 200,
      // height: 200,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H,
    });
  }
}

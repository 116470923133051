
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core//mixins/mixin";
import { NavBar, Row, Image } from "vant";
import ItemTitle from "./ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class PayBtn extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public orderStatus!: string;
  @Prop({
    type: String,
    default: "",
  })
  public evaluation!: string;
  @Prop({
    type: Boolean,
    default: false,
  })
  public isDouyin!: boolean;
  onCancel(): void {
    this.$emit("payCancel");
  }
  onConfirm(): void {
    this.$emit("payConfirm");
  }
  onComment() {
    this.$emit("comment");
  }
}

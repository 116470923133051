
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image, Step, Steps } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Step.name]: Step,
    [Steps.name]: Steps,
    ItemTitle,
  },
})
export default class PerSteps extends Mixins(Mixin) {
  @Prop({
    type: Number,
    default: 0,
  })
  public steps!: number; //进度
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public SetData!: order.ReturnGoodInfo[]; //退款信息
  //处理时间
  timeFilter(time?: string): string {
    if (!time) {
      return "";
    }
    let date = new Date(time);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let month: number | string = "";
    let days: number | string = "";
    let hours: number | string = "";
    month = m < 10 ? "0" + m : m;
    let d = date.getDate();
    days = d < 10 ? "0" + d : d;
    let h = date.getHours();
    hours = h < 10 ? "0" + h : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let min = minute < 10 ? "0" + minute : minute;
    let s = second < 10 ? "0" + second : second;
    return y + "." + month + "." + days + " " + hours + ":" + min;
  }
}

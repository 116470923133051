
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core//mixins/mixin";
import { NavBar, Row, Image, Icon } from "vant";
import ItemTitle from "./ItemTitle.vue";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Icon.name]: Icon,
    ItemTitle,
  },
})
export default class InvoiceInfo extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "凭票到剧院窗口开具发票",
  })
  public invoiceExplain!: string; //发票说明
  @Prop({
    type: String,
    default: "",
  })
  public invoiceStatus!: string; //发票状态,1已经开票，2未开票
  @Prop({
    type: Number,
    default: null,
  })
  public showTime!: number; //演出时间
  @Prop({
    type: Number,
    default: null,
  })
  public productOwnerGroup!: number; //演出时间
  @Prop({
    type: Boolean,
    default: true,
  })
  public isMall!: boolean; //演出时间
  @Prop({
    type: Number,
    default: null,
  })
  public actuallyPaidAmt!: number; //票务总价
  // @Prop({
  //   type: Boolean,
  //   default: true,
  // })
  // public isCash!: boolean; //是由有现金，有现金的情况下才会有发票
  orderId = "";
  isShow = true;
  @Watch("showTime", { immediate: true, deep: true })
  updateTime(): void {
    if (this.showTime) {
      //票务要判断结束时间前才能开发票
      this.timeFilter(this.showTime);
    } else {
      this.isShow = true;
    }
  }
  created(): void {
    this.orderId = this.$route.params.orderId || "";
  }
  timeFilter(time: number): void {
    let date = new Date(time);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let month = m < 10 ? "0" + m : m;
    let d = date.getDate();
    let days = d < 10 ? "0" + d : d;
    let timer = `${y}/${month}/${days} 23:59:59`;
    let showEndTime = new Date(timer).getTime();
    if (Number(showEndTime) >= Number(new Date().getTime())) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  invoiceName(type: string): string {
    let name = "";
    if (type === "1") {
      name = "已申请开票";
    } else if (type === "2") {
      name = "申请开票";
    }
    return name;
  }
  /*
   *开发票
   */
  goInvoice(): void {
    if (this.actuallyPaidAmt === 0) {
      this.$toast("发票金额必须大于0元");
      return;
    }
    let type = "";
    if (this.isMall) {
      type = "2";
    } else {
      type = "1";
    }
    if (this.invoiceStatus === "1") {
      this.$router.push(`/invoice-detail?orderId=${this.orderId}`);
      monitorEvent("OrderDetail_ClickViewFaPiao", "点击查看发票按钮"); // 埋点：订单详情，点击查看发票按钮
    } else if (this.invoiceStatus === "2") {
      monitorEvent("OrderDetail_ClickFaPiao", "点击申请发票按钮"); // 埋点：订单详情，点击申请发票按钮
      this.$router.push(`/invoice/${this.orderId}/${type}`);
    }
  }
}


import { Mixin } from "@/core/mixins/mixin";
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Popup } from "vant";

@Component({
  components: {
    Mixin,
    [Popup.name]: Popup,
  },
})
export default class Survery extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return {};
    },
    type: String,
  })
  orderInfo!: order.EsOrderDetailTicketInfo; // 订单详情
  @Watch("orderInfo")
  isShowSurvery() {
    this.showSurvery = this.orderInfo.questionnaire || false;
  }
  showSurvery = false;
  showComment = false;
  index = 0;
  form = {
    like: false,
    recommend: false,
  };
  surveryList = [
    {
      title: "您对本场演出满意吗？",
      answer: undefined as boolean | undefined,
      question: [
        {
          text: "不满意",
          value: "0",
        },
        {
          text: "满意",
          value: "1",
        },
      ],
    },

    {
      title: "您是否推荐本场演出？",
      answer: undefined as boolean | undefined,
      question: [
        {
          text: "不推荐",
          value: "0",
        },
        {
          text: "推荐",
          value: "1",
        },
      ],
    },
  ];
  chooseAnswer(value: string) {
    this.surveryList[this.index].answer = value === "1";
    setTimeout(() => {
      if (value === "0" || this.index === 1) {
        if (value === "0") {
          this.$toast("谢谢您的选择，我们会继续努力");
        }
        this.$api.orderApi.ticketOrder.saveOrderTicketQuestionnaire(
          {
            like: this.surveryList[0].answer,
            recommend: this.surveryList[1].answer,
            orderTicketId: this.orderInfo.orderId,
          },
          ({ data }) => {
            this.showSurvery = false;
            if (value === "1") {
              this.showComment = true;
            }
          }
        );
      } else {
        this.index = 1;
      }
    }, 1000);
  }
  gotoComment() {
    this.$router.push(
      `/write-comment/${this.orderInfo.productId}?orderId=${this.orderInfo.orderId}&productName=${this.orderInfo.productName}`
    );
  }
}
